<template>
  <div>
    <v-card>
      <v-card-title v-if="load_graph"
        >Bienvenid@ : {{ full_name }}</v-card-title
      >
      <v-container>
        <v-row justify="center" v-if="load_graph">
          <v-col cols="11" md="9"> </v-col>
        </v-row>
        <v-row v-if="is_teacher" justify="center">
          <v-col cols="12" align-self="center">
            <graph-resume />
          </v-col>
        </v-row>
        <div v-if="is_admin">
          <v-card-title
            >Bienvenido al sistema de notas del Colegio Cramer</v-card-title
          >
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.config";
//import NotasBar from "../components/charts/NotasBar.vue";
import GraphResume from "../components/TeacherComponents/GraphResume.vue";
export default {
  name: "Home",
  data: () => ({
    full_name: "",
    load_graph: false,
    is_teacher: false,
    is_admin: false,
  }),
  components: {
    //  NotasBar,
    GraphResume,
  },
  methods: {
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },
    get_user() {
      let datalocal = JSON.parse(localStorage.getItem("token-user"));
      let student_id = datalocal.id;
      let is_student = datalocal.rol;
      if (is_student == "student") {
        this.load_graph = true;
        axios
          .get(
            api.API_PATH + "/api/get-student-info/" + student_id + "/",
            this.get_config()
          )
          .then((res) => {
            this.full_name =
              res.data[0].name +
              " " +
              res.data[0].paterno +
              " " +
              res.data[0].materno;
          })
          .catch((e) => {
            console.log(e);
          });
      }
      if (is_student == "teacher") {
        this.is_teacher = true;
      } else if (is_student == "admin") {
        this.is_admin = true;
      }
    },
  },
  mounted() {
    this.get_user();
  },
};
</script>
