<template>
  <div>
    <v-card>
      <v-card-title
        >Bienvenido al sistema de notas del colegio Cramer</v-card-title
      >
      <v-card-subtitle>Resumen de estado académico de alumnos</v-card-subtitle>
      <v-container v-if="loaded">
        <v-row>
          <v-col cols="10" md="3">
            <v-select
              return-object
              :items="periodos_academicos"
              item-text="title"
              item-value="id"
              label="Seleciona el periodo"
              v-model="id_periodo_selected"
              @change="set_formato_grafico()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="10" sm="10" :md="cols_primaria">
            <apexchart
              v-if="status_primaria && show_primaria_graph"
              height="400"
              width="100%"
              type="bar"
              :options="chartOptionsPrimaria"
              :series="seriesPrimaria"
            ></apexchart>
          </v-col>
          <v-col cols="10" sm="10" :md="cols_secundaria">
            <apexchart
              v-if="status_secundaria && show_secundaria_graph"
              height="400"
              width="100%"
              type="bar"
              :options="chartOptionsSecundaria"
              :series="seriesSecundaria"
            ></apexchart>
          </v-col>
        </v-row>
      </v-container>
      <v-progress-linear
        v-else
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import api from "@/api.config.js";
import { mapState } from "vuex";
export default {
  name: "GraphResume",
  components: {
    apexchart: VueApexCharts,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  data: () => ({
    loaded: false,
    status_primaria: false,
    status_secundaria: false,
    show_primaria_graph: false,
    show_secundaria_graph: false,
    cols_primaria: "6",
    cols_secundaria: "6",
    periodos_academicos: [],
    grupos_primaria: [],
    grupos_secundaria: [],
    ids_cursos: [],
    data_reporte: [],
    id_periodo_selected: { id: 1 },
    seriesPrimaria: [
      {
        name: "Aprobados",
        data: [],
      },
      {
        name: "Desaprobados",
        data: [],
      },
    ],
    chartOptionsPrimaria: {
      title: {
        text: "Primaria",
      },
      grid: {
        padding: {
          left: 60, // or whatever value that works
          right: 60, // or whatever value that works
          bottom: 60,
        },
      },
      theme: {
        // mode:'dark',
      },
      legend: {
        position: "top",
      },
      colors: ["#54EE71", "#E91E63"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " alumnos";
          },
        },
      },
    },
    seriesSecundaria: [
      {
        name: "Aprobados",
        data: [],
      },
      {
        name: "Desaprobados",
        data: [],
      },
    ],
    chartOptionsSecundaria: {
      theme: {
        // mode:'dark',
      },
      grid: {
        padding: {
          left: 60, // or whatever value that works
          right: 60, // or whatever value that works
          bottom: 60,
        },
      },
      title: {
        text: "Secundaria",
      },
      legend: {
        position: "top",
      },
      colors: ["#54EE71", "#E91E63"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        //background: '#1e1e1e'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " alumnos";
          },
        },
      },
    },
  }),
  computed: {
    ...mapState(["theme_state"]),
  },
  watch: {
    theme_state: function(val) {
      this.cambiar_tema(val);
    },
  },
  methods: {
    convertir_grupo_string(d) {
      if (d == "Primero") return "1°";
      else if (d == "Segundo") return "2°";
      else if (d == "Tercero") return "3°";
      else if (d == "Cuarto") return "4°";
      else if (d == "Quinto") return "5°";
      else if (d == "Sexto") return "6°";

      return "";
    },
    obtener_curso_por_id(id) {
      let curso = this.ids_cursos.find((e) => e.id == id);
      return curso.nombre;
    },
    obtener_grupo_por_id(id) {
      let grupo = this.grupos_primaria
        .concat(this.grupos_secundaria)
        .find((e) => e.id == id);
      return grupo.grupo;
    },
    comprobar_pertenencia_grupo_primaria(id) {
      let aux_p = this.grupos_primaria.find((e) => e.id == id);
      if (aux_p) return true;
      else return false;
    },
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },
    get_grupos() {
      let localdata = JSON.parse(localStorage.getItem("token-user"));
      axios
        .get(
          api.API_PATH + "/api/cursos-por-profesor/" + localdata.id + "/",
          this.get_config()
        )
        .then((response) => {
          let aux = response.data;
          let primaria = [];
          let secundaria = [];
          //console.log(aux)
          aux.forEach((e) => {
            if (e.grupo.nivel.descripcion == "Primaria") {
              primaria.push({
                grupo:
                  this.convertir_grupo_string(e.grupo.grado.descripcion) +
                  "" +
                  e.grupo.seccion.descripcion,
                id: e.grupo.id,
                cursos: e.curso,
                nivel: "Primaria",
              });
            } else {
              secundaria.push({
                grupo:
                  this.convertir_grupo_string(e.grupo.grado.descripcion) +
                  "" +
                  e.grupo.seccion.descripcion,
                id: e.grupo.id,
                cursos: e.curso,
                nivel: "Secundaria",
              });
            }
          });
          this.grupos_primaria = primaria;
          this.grupos_secundaria = secundaria;
          if (primaria.length > 0) {
            this.show_primaria_graph = true;
          } else {
            this.cols_secundaria = "10";
          }
          if (secundaria.length > 0) {
            this.show_secundaria_graph = true;
          } else {
            this.cols_primaria = "10";
          }
          let cursos = [];
          cursos = primaria.concat(secundaria);
          let ids_cursos = [];
          let cur = new Set();
          cursos.forEach((e) => {
            e.cursos.forEach((ele) => {
              cur.add({ nombre: ele.nombre_curso, id: ele.id });
            });
          });
          for (const i of cur) {
            ids_cursos.push(i);
          }
          this.ids_cursos = ids_cursos;
          //console.log(this.obtener_curso_por_id(1));
          //console.log(this.obtener_grupo_por_id(1));

          //console.log(primaria)

          this.get_report_of_students();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_report_of_students() {
      let localdata = JSON.parse(localStorage.getItem("token-user"));
      axios
        .get(
          api.API_PATH +
            "/api/get-reporte-estado-por-curso/" +
            localdata.id +
            "/",
          this.get_config()
        )
        .then((r) => {
          this.data_reporte = r.data;
          this.set_formato_grafico();
          this.loaded = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_periodo_academico() {
      let anio = 2021;
      axios
        .get(
          api.API_PATH + "/api/get-periodo-academico/" + anio + "/",
          this.get_config()
        )
        .then((r) => {
          let aux = r.data;
          console.log("periodos", r.data);
          this.periodos_academicos = [];
          aux.sort(function(a, b) {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
          aux.forEach((e) => {
            this.periodos_academicos.push({
              id: e.id,
              title: e.denominacion,
            });
          });
          this.get_grupos();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    set_formato_grafico() {
      this.status_primaria = false;
      this.status_secundaria = false;
      this.seriesPrimaria[0].data = [];
      this.seriesPrimaria[1].data = [];
      this.chartOptionsPrimaria.xaxis.categories = [];
      let aux = this.data_reporte.filter(
        (e) => e.periodo_academico == this.id_periodo_selected.id
      );
      //console.log(aux)
      let prim_arr = aux.filter((e) =>
        this.comprobar_pertenencia_grupo_primaria(e.grupo)
      );
      prim_arr.forEach((e) => {
        this.seriesPrimaria[0].data.push(e.aprobados);
        this.seriesPrimaria[1].data.push(e.desaprobados);
        this.chartOptionsPrimaria.xaxis.categories.push(
          this.obtener_grupo_por_id(e.grupo) +
            "-" +
            this.obtener_curso_por_id(e.curso)
        );
      });

      this.seriesSecundaria[0].data = [];
      this.seriesSecundaria[1].data = [];
      this.chartOptionsSecundaria.xaxis.categories = [];
      let secu_arr = aux.filter(
        (e) => !this.comprobar_pertenencia_grupo_primaria(e.grupo)
      );
      secu_arr.forEach((e) => {
        this.seriesSecundaria[0].data.push(e.aprobados);
        this.seriesSecundaria[1].data.push(e.desaprobados);
        this.chartOptionsSecundaria.xaxis.categories.push(
          this.obtener_grupo_por_id(e.grupo) +
            "-" +
            this.obtener_curso_por_id(e.curso)
        );
      });
      //console.log(this.seriesPrimaria)
      this.$nextTick(() => {
        this.status_primaria = true;
        this.status_secundaria = true;
      });
    },
    cambiar_tema(val) {
      this.status_secundaria = false;
      this.status_primaria = false;
      if (val) {
        this.$nextTick(() => {
          this.chartOptionsSecundaria.theme.mode = "dark";
          this.chartOptionsPrimaria.theme.mode = "dark";

          this.chartOptionsSecundaria.chart.background = "#1e1e1e";
          this.chartOptionsPrimaria.chart.background = "#1e1e1e";

          this.status_secundaria = true;
          this.status_primaria = true;
        });
      } else {
        this.$nextTick(() => {
          this.chartOptionsSecundaria.theme.mode = "light";
          this.chartOptionsSecundaria.chart.background = "#FFFFFF";
          this.status_secundaria = true;
          this.chartOptionsPrimaria.theme.mode = "light";
          this.chartOptionsPrimaria.chart.background = "#FFFFFF";
          this.status_primaria = true;
        });
      }
    },
  },

  mounted() {
    this.get_periodo_academico();
    this.cambiar_tema(this.theme_state);
  },
};
</script>
